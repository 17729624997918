table td {
    font-size: 0.7rem;
}

.bids {
    color: green;
}

.asks, table>tr.asks {
    color: red;
}
